.button {
  background: c(primary);
  line-height: 0;
  cursor: pointer;
  color: c(light);
  transition: all 300ms;
  position: relative;
  border: rem(1) solid transparent;
  > p,
  & {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
  &.outline {
    &.primary {
      border-color: c(primary);
      color: c(primary);
    }
    &.primary-border {
      border-color: c(primary);
    }
    border-color: c(text, 0.8);
    background: transparent;
    color: c(text, 0.8);
  }
  &.transparent {
    background: transparent;
    color: c(primary);
    text-decoration: underline;
    text-underline-offset: rem(4);
    &.gray {
      color: c(text, 0.5);
    }
  }
  &.loading {
    pointer-events: none;
    > p {
      opacity: 0;
    }
  }
  .icon-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: rem(28);
    &:before {
      animation: loader 300ms infinite linear;
      display: block;
    }
  }
  > p {
    gap: rem(12);
    > i {
      font-size: rem(24);
      line-height: 0;
    }
  }
  &.fullWidth {
    width: 100%;
  }
  &.small {
    height: rem(41);
    padding: 0 rem(20);
    border-radius: rem(28);
  }
  &.large {
    height: rem(106);
    padding: 0 rem(100);
    font-size: rem(34);
    border-radius: rem(60);
    .icon-loader {
      font-size: rem(50);
    }
  }
  &:not(.large):not(.small) {
    height: rem(60);
    padding: 0 rem(30);
    font-size: rem(24);
    border-radius: rem(50);
  }
}
input[type='text'],
input[type='date'],
input[type='number'],
input[type='password'],
input[type='email'],
input[type='search'],
input[type='search'],
input[type='tel'],
textarea,
select {
  padding: 0 rem(10);
  font-size: rem(18);
  width: 100%;
  min-height: rem(60);
  background: transparent;
  color: c(text);
  font-weight: 400;
  @include placeholder {
    color: c(text, 0.5);
  }
  & + input,
  & + .input-block {
    margin-top: rem(20);
  }
}
.input-content {
  border-radius: rem(24);
  overflow: hidden;
  border: rem(2) solid c(text, 0.5);
  padding: 0 rem(15);
  display: flex;
  align-items: center;
  .icon-calendar-outline {
    font-size: rem(24);
    padding-right: rem(8);
  }
  .input-before {
    min-height: rem(60);
    border-right: rem(2) solid c(text, 0.5);
    padding: 0 rem(25) 0 rem(10);
    margin-right: rem(5);
    display: flex;
    line-height: rem(60);
    font-size: rem(18);
    font-weight: 400;
  }
  .icon-check {
    width: rem(40);
    height: rem(40);
    flex-shrink: 0;
    line-height: 0;
    color: c(light);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: c(success-second);
  }
  .code-block {
    .timer {
      color: c(love);
      padding: rem(10);
    }
    .code {
      text-decoration: underline;
      text-underline-offset: rem(2);
      padding: rem(5) rem(10);
      cursor: pointer;
      color: c(primary);
      white-space: nowrap;
    }
  }
}
.form-wrapper,
.form-group {
  display: flex;
  flex-direction: column;
}
.form-button {
  display: flex;
  &:not(.with-policy) {
    justify-content: flex-end;
    &.align-end {
      align-self: flex-end;
    }
  }
  &.with-policy {
    align-items: center;
    justify-content: space-between;
  }
}
.double-columns {
  display: flex;
  &.center {
    align-items: center;
  }
  &.g20 {
    gap: rem(20);
  }
  &.g40 {
    gap: rem(40);
  }
}
.form-group {
  &.g20 {
    gap: rem(20);
  }
  &.g40 {
    gap: rem(40);
  }
  .symmetric-blocks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &.g20 {
      gap: rem(20);
    }
    &.g40 {
      gap: rem(40);
    }
    .double-columns {
      padding: 0 rem(10);
    }
  }
}
.form-wrapper {
  gap: rem(30);
}
.single-input {
  width: 100%;
  &.isInvalid {
    .message {
      color: c(love);
    }
    .input-content {
      border-color: c(love);
      input,
      .input-before {
        color: c(love);
        border-color: c(love);
        @include placeholder {
          color: c(love);
        }
      }
    }
  }
  &.highlighted {
    .input-content {
      border-color: c(primary);
    }
  }
  &.disabled {
    .input-content {
      pointer-events: none;
      opacity: 0.8;
    }
  }
  label {
    display: block;
    padding: 0 rem(20);
    margin-bottom: rem(16);
    font-size: rem(18);
    font-weight: 600;
    opacity: 0.7;
    min-height: rem(22);
    small {
      font-size: rem(12);
      line-height: rem(22);
      opacity: 0.8;
    }
  }
  .message {
    margin-top: rem(8);
    padding: 0 rem(25);
    color: c(text, 0.6);
    font-size: rem(14);
    overflow: hidden;
    max-height: rem(35);
    transition: all 400ms;
    &:empty {
      margin-top: 0;
      max-height: 0;
    }
    .edit {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      gap: rem(6);
      margin-left: rem(10);
      color: c(primary);
      > i {
        font-size: rem(16);
      }
      b {
        font-weight: normal;
        text-decoration: underline;
        text-underline-offset: rem(2);
      }
    }
  }
}
.radio,
.checkbox {
  cursor: pointer;
  display: flex;
  gap: rem(12);
  position: relative;
  font-size: inherit;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    &:checked {
      pointer-events: none;
      & + .dot,
      & + .square {
        border-color: c(primary);
        &:before,
        &:before {
          transform: scale(1);
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: rem(2);
    .title {
      line-height: rem(26);
    }
    .description {
      color: c(text, 0.5);
      font-size: rem(14);
    }
    .price {
      font-weight: 400;
      font-size: rem(20);
      margin-top: rem(4);
    }
  }
  .dot,
  .square {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: border 300ms;
    &:before {
      content: '';
      background: c(primary);
      display: block;
      transform: scale(0);
      transition: transform 400ms;
    }
  }
  .dot {
    width: rem(24);
    height: rem(24);
    border: rem(2) solid c(main-2, 0.5);
    border-radius: 50%;
    &:before {
      border-radius: 50%;
      width: rem(12);
      height: rem(12);
    }
  }
  .square {
    width: rem(32);
    height: rem(32);
    border: rem(2) solid c(main-2, 0.5);
    border-radius: rem(8);
    &:before {
      border-radius: rem(4);
      width: rem(18);
      height: rem(18);
    }
  }
}

@keyframes loader {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
