@font-face {
    font-family: 'SFProText';
    src: url('SFProText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'SFProText';
    src: url('SFProText-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'SFProText';
    src: url('SFProText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Lato VH';
    src: url('subset-LatoVH.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Lato VH';
    src: url('subset-LatoVH-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}


