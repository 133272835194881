@import 'assets/scss/core';

.language-line-mode {
  display: flex;
  gap: rem(50);
  > div {
    cursor: pointer;
    background: c(main-2, 0.3);
    border-radius: rem(24);
    font-size: rem(38);
    line-height: 1;
    font-weight: 400;
    padding: rem(24) rem(28);
    text-transform: uppercase;
    transition: border 300ms;
    width: rem(142);
    text-align: center;
    &.selected {
      font-weight: 700;
      background: c(primary);
      color: c(light);
    }
  }
}
.language-select-wrapper {
  transition: opacity 400ms;
  opacity: 1;
  position: relative;
  .isHome & {
    opacity: 0;
    visibility: hidden;
  }
  .item {
    height: rem(50);
    background: c(main-2, 0.2);
    border-radius: rem(16);
    font-size: rem(24);
    font-weight: 400;
    min-width: rem(90);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    border: rem(2) solid transparent;
    transition: border 400ms;
    line-height: 1;
    &.selected {
      border-color: c(primary);
    }
  }
  .other-items {
    display: flex;
    gap: rem(16);
    position: absolute;
    top: rem(66);
    flex-direction: column;
    right: rem(0);
    opacity: 0;
    transform: translateY(rem(-6));
    transition: all 400ms;
    .item {
      background: c(light);
      box-shadow: inset 0 0 0 rem(40) c(main-2, 0.2);
    }
    &.open {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
