@use 'sass:math';

$colors: (
  primary: #f58032,
  golden: #e6b91c,
  premiere: #ffa100,
  text: #0f1927,
  primary-sc: #ffffff,
  danger: #ed2e41,
  premium: #ed4c5c,
  love: #f94053,
  success: #70d423,
  success-second: #00bc35,
  success-light: #bedfa5,
  warning: #fdb73a,
  background: #ffffff,
  main-1: #1a293f,
  main-2: #8c949d,
  main-3: #4186bf,
  main-4: #264565,
  main-5: #dddfe2,
  light: #ffffff,
  dark: #000000,
);

@function c($color, $opacity: 1) {
  @if $opacity == 1 {
    @return var(--#{$color});
  }
  @return rgba(var(--#{$color}-rgb), $opacity);
}
@mixin placeholder {
  $prefixList: '::-webkit-input', ':-moz', '::-moz', ':-ms-input';
  @each $prefix in $prefixList {
    &#{$prefix}-placeholder {
      @content;
      opacity: 1;
    }
  }
}
@function rem($size) {
  $remSize: math.div($size, 10);
  @if $size == 1 {
    @return #{$size}px;
  }
  @return #{$remSize}rem;
}
@each $name, $color in $colors {
  $colors: map-merge($colors, (#{$name + '-rgb'}: #{red($color), green($color), blue($color)}));
  $colors: map-merge($colors, (#{$name + '-hover'}: darken($color, 5)));
}
@mixin scroll-y($scroll-main-thumb-color: c(main-4, 0.5)) {
  $border-radius: rem(4);
  $width: 2px;
  $scroll-main-color: transparent;

  scrollbar-track-color: $scroll-main-color;
  scrollbar-shadow-color: $scroll-main-color;
  scrollbar-base-color: $scroll-main-thumb-color;
  scrollbar-arrow-color: $scroll-main-thumb-color;
  &::-webkit-scrollbar {
    width: $width;
    background: $scroll-main-color;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background: $scroll-main-thumb-color;
  }
}
@mixin hover {
  &:active,
  &:hover {
    @content;
  }
}
