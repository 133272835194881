@import 'assets/scss/core';

.popup-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  background: rgba(26, 41, 63, 0.2);
  backdrop-filter: blur(15px);
  top: 0;
  left: 0;
  z-index: 98;
  padding: rem(156) rem(41) rem(41);
  animation: bg-fade 400ms backwards;
  transition: padding 400ms;
  @media print {
    display: none;
  }
  &.over-header {
    z-index: 106;
    padding: rem(41);
  }
  &.over {
    z-index: 102;
  }
  &:has(+ .selected-item-wrapper) {
    padding-bottom: rem(155);
  }
  .popup-container {
    max-width: rem(1000);
    background: c(light);
    display: flex;
    align-self: safe center;
    margin: rem(40) 0;
    flex-direction: column;
    border-radius: rem(36);
    padding: rem(46) rem(44);
    animation: popup-fade 400ms backwards;
    position: relative;
    &:not(.content) {
      width: 100%;
    }
    .header {
      display: flex;
      justify-content: space-between;
      z-index: 4;
      width: 100%;
      height: rem(47);
      overflow: hidden;
      top: 0;
      left: 0;
      pointer-events: none;
      margin-bottom: rem(30);
      &.absolute {
        position: absolute;
        padding: rem(46) rem(44);
      }
      h4 {
        font-size: rem(44);
        font-weight: 600;
        line-height: 1;
        &:first-letter {
          text-transform: capitalize;
        }
        > i {
          font-size: rem(130);
          display: flex;
          align-items: center;
          height: 100%;
        }
      }
      &.disabled {
        .icon-cross {
          opacity: 0.3;
          pointer-events: none;
        }
      }
      .close {
        display: inline-flex;
        align-items: center;
        gap: rem(14);
        line-height: 1;
        height: rem(40);
        > span {
          font-size: rem(24);
        }
        .timer {
          opacity: 0.5;
          display: inline-block;
          min-width: rem(33);
          text-align: left;
          line-height: 1;
          font-weight: bold;
        }
      }
      .icon-cross {
        font-size: rem(40);
        cursor: pointer;
        pointer-events: visible;
      }
    }
  }
}

@keyframes bg-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes popup-fade {
  from {
    transform: scale(0.7);
  }
  to {
    transform: scale(1);
  }
}
.attention-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: rem(550);
  .at-title {
    font-size: rem(24);
    color: c(danger);
    text-transform: uppercase;
  }
  > p {
    margin-top: rem(30);
    font-size: rem(18);
  }
  > button,
  .popup-button-block {
    margin-top: rem(40);
  }
}
.popup-button-block {
  margin-top: rem(30);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.center {
    justify-content: center;
    gap: rem(40);
  }
}
