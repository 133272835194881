@import 'core.scss';
@import 'reset.scss';
@import 'inputs.scss';
@import 'datepicker.scss';
@import '../icons/style';
@import '../fonts/stylesheet.css';

:root {
  --font: 'SFProText', 'Lato VH', sans-serif;
  @each $name, $color in $colors {
    --#{$name}: #{$color};
  }
}

html {
  font: 10px / 1.2 var(--font);
  overflow: hidden;
  @media screen and (max-height: 1000px) {
    font-size: 0.52vh;
  }
}

body {
  font-size: rem(16);
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  color: c(text);
  background: c(background);
  &:has(.tooltip-wrapper) {
    main {
      padding-bottom: rem(160);
    }
  }
  &:has(.selected-item-wrapper) {
    &:has(.tooltip-wrapper) {
      main {
        padding-bottom: rem(275);
      }
    }
    main {
      padding-bottom: rem(184);
    }
    .tooltip-wrapper {
      bottom: rem(163);
    }
  }
}

#root {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  width: rem(1080);
  height: rem(1920);
  aspect-ratio: 9 / 16;
  margin: 0 auto;
  @media print {
    display: none;
  }
  @include scroll-y();
}
#print {
  display: none;
  @media print {
    display: flex;
  }
}

main {
  padding: 0 rem(42) rem(40);
  flex: 1;
}

.banner-image {
  width: 100%;
  height: rem(350);
  border-radius: rem(24);
  object-fit: cover;
}

.mt-30 {
  margin-top: rem(30);
}
.mb-30 {
  margin-bottom: rem(30);
}
.mt-50 {
  margin-top: rem(50);
}
.mb-50 {
  margin-bottom: rem(50);
}
.pb-42 {
  padding-bottom: rem(42);
}
.pb-0 {
  padding-bottom: 0 !important;
}
.sticky-content {
  position: sticky;
  top: rem(156);
  background: c(light);
  padding-bottom: rem(16);
  z-index: 4;
  margin-left: rem(-20);
  width: calc(100% + #{rem(40)});
  padding-left: rem(20);
  padding-right: rem(20);
}
.floating-item-animation {
  z-index: 4 !important;
  position: absolute !important;
  animation: floating-item-anim 600ms ease-in-out forwards;
}
@keyframes floating-item-anim {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.2);
  }
  80% {
    left: rem(20);
  }
  100% {
    left: rem(20);
    top: 95vh;
    transform: scale(0.7);
  }
}
.paper {
  width: 100%;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  padding-bottom: rem(30);
  border-bottom: rem(1) solid c(text, 0.3);
  &.gift-card {
    .total,
    .qr {
      display: none;
    }
  }
  .thank-you {
    font-weight: bold;
    text-align: center;
    margin-top: rem(30);
    font-size: rem(14);
  }
  .qr {
    width: rem(140);
    height: rem(140);
    margin: 30px auto 0;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    & + table {
      margin-top: rem(40);
    }
    > img {
      height: 30px;
    }
    > p {
      margin-top: 30px;
    }
  }
  .total {
    margin-top: rem(20);
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
    font-size: 14px;
  }
  table {
    border-collapse: collapse;
    & + table {
      margin-top: 20px;
    }
    th {
      text-transform: uppercase;
      &:first-child {
        width: 100%;
      }
    }
    td,
    th {
      border: 1px solid c(text, 0.5);
      vertical-align: middle;
      padding: 4px;
      &:not(:first-child) {
        white-space: nowrap;
      }
    }
    th {
      text-align: left;
    }
  }
  ul {
    margin: 40px 0 30px;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    & + li {
      border-top: 1px solid c(text, 0.3);
      padding-top: 8px;
      margin-top: 8px;
    }
    b {
      text-transform: uppercase;
    }
    p {
      text-align: right;
      > p {
        & + p {
          margin-top: 8px;
        }
      }
    }
  }
}
.page-fade {
  animation: page-fade 400ms backwards;
}
.error-popup {
  min-width: rem(400);
  max-width: rem(500);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: rem(28);
  > i {
    font-size: rem(80);
  }
  > p {
    font-size: rem(20);
    text-align: center;
    line-height: 1.3;
  }
  > button {
    margin-top: rem(14);
  }
}

.align-center {
  text-align: center;
}

@keyframes page-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
