@import 'assets/scss/core';

header {
  height: rem(156);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: c(background);
  position: sticky;
  top: 0;
  flex-shrink: 0;
  left: 0;
  z-index: 105;
  width: 100%;
  padding: 0 rem(42);
  img {
    height: rem(92);
    cursor: pointer;
  }
}
