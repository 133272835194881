$color_2: #757575;
$color_3: white;
$background-color_1: #f0f0f0;
$background-color_2: #e6e6e6;

.react-calendar {
  width: rem(600);
  max-width: 100%;
  line-height: 1.125em;
}
.react-calendar__navigation {
  display: flex;
  height: rem(44);
  gap: rem(12);
  margin-bottom: 1em;
  button {
    border-radius: rem(12);
    font-size: rem(20);
    min-width: rem(44);
    background: none;
    text-transform: uppercase;
    &:disabled {
      opacity: 0.5;
      background-color: $background-color_1;
    }
    &:enabled {
      &:hover {
        background-color: $background-color_2;
      }
      &:focus {
        background-color: $background-color_2;
      }
    }
  }
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: rem(16);
  line-height: 1;
  font-weight: bold;
  height: rem(40);
  align-items: center;
  border-bottom: rem(1) solid c(main-5);
  margin-bottom: rem(6);
  abbr {
    text-decoration: none;
  }
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: rem(14);
    font-weight: bold;
    border-radius: rem(12);
  }
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: $color_2;
}
.react-calendar__tile {
  max-width: 100%;
  padding: rem(20) rem(6);
  background: none;
  text-align: center;
  line-height: rem(16);
  font: inherit;
  font-size: rem(18);
  border: rem(1) solid c(light);
  border-radius: rem(12);
  &:disabled {
    opacity: 0.5;
    background-color: $background-color_1;
  }
}
.react-calendar__tile--active {
  background: c(primary);
  color: c(primary-sc);
  &:enabled {
    &:focus {
      color: c(primary-sc);
      background: c(primary);
    }
  }
}
.react-calendar--selectRange {
  .react-calendar__tile--hover {
    background-color: $background-color_2;
  }
}
