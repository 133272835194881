@import 'assets/scss/core';

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: rem(40);
  height: rem(1000);
  > img {
    height: rem(50);
  }
  .lottie-loader {
    height: rem(150);
  }
}
