@import 'assets/scss/core';

.time-block {
  border: rem(2) solid c(main-2, 0.6);
  height: rem(50);
  border-radius: rem(16);
  font-size: rem(24);
  font-weight: 400;
  width: rem(90);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 400ms;
  .isHome & {
    opacity: 0;
    visibility: hidden;
  }
}
